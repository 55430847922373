const OSS = require('ali-oss');
import config from './config';
function Client() {
    return new OSS(config);
}

export default function uploadFile(fileList, path = '') {
    let list = [];
    return new Promise((resolve, reject) => {
        //判断fileList是否为数组
        if (!(fileList instanceof Array)) {
            fileList = [fileList];
        }
        fileList.forEach((item) => {
            list.push(upLoad(path, item.file));
        });
        Promise.all(list).then((res) => {
            resolve(res);
        });
    });
}

let upLoad = (path, file) => {
    return new Promise((resolve, reject) => {
        let client = Client();
        //获取文件名后缀
        let suffix = file.name.split('.').pop();
        let name = new Date().getTime();
        let fieldName = name + '.' + suffix;
        let date = getNowFormatDate();
        if (path == '') path = 'default/' + date + '/';
        client.put(path + fieldName, file).then((res) => {
            resolve(res.url);
        });
    });
};

//生成标准日期
function getNowFormatDate() {
    let date = new Date();
    let seperator1 = '-';
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = '0' + month;
    }
    let currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
    return currentdate;
}
